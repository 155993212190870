export const LogoWithText = (props: Record<string, string | number>) => (
  <svg
    aria-label='Galileo Logo'
    fill='none'
    height='100%'
    viewBox='0 0 2170 744'
    width='100%'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M1058.55 482.288C1038.26 482.288 1019.46 478.517 1001.42 470.976C983.38 463.435 968.345 452.877 954.814 439.303C941.283 425.729 930.758 409.893 923.241 391.794C915.724 373.695 911.965 354.842 911.965 333.726C911.965 312.611 915.724 293.759 923.241 275.66C930.758 257.561 941.283 242.479 954.814 228.905C968.345 215.331 984.132 204.773 1002.17 197.232C1020.22 189.691 1039.76 185.92 1060.81 185.92C1084.11 185.92 1105.91 190.445 1126.21 199.494C1145.76 208.544 1162.29 220.61 1175.07 237.2L1137.49 274.906C1128.47 262.84 1117.19 254.545 1104.41 247.758C1090.88 241.725 1076.6 238.708 1060.81 238.708C1042.77 238.708 1026.23 242.478 1012.7 250.774C998.415 259.069 987.89 269.627 979.621 283.955C972.104 298.283 967.593 314.874 967.593 333.726C967.593 352.579 971.352 369.925 978.869 384.253C986.387 398.581 996.911 409.893 1010.44 418.188C1023.97 426.483 1039.01 430.254 1056.3 430.254C1074.34 430.254 1089.38 427.237 1102.15 420.45C1114.93 413.663 1124.71 403.86 1131.47 391.04C1138.24 378.22 1142 362.383 1142 343.53L1178.08 368.416L1053.29 366.908V318.644H1199.13V326.185C1199.13 360.12 1193.11 388.777 1181.09 412.155C1169.06 434.778 1151.77 452.123 1130.72 464.189C1111.18 476.255 1086.37 482.288 1058.55 482.288Z'
      fill={(props.fill as string) || 'black'}
    />
    <path
      d='M1316.46 482.288C1298.52 482.288 1282.83 477.756 1268.64 468.694C1254.44 459.632 1243.23 447.548 1235.01 431.689C1226.79 415.83 1223.06 398.461 1223.06 379.581C1223.06 359.946 1226.79 342.577 1235.01 326.718C1243.23 310.858 1254.44 298.775 1268.64 289.713C1282.83 280.65 1299.27 276.119 1316.46 276.119C1330.65 276.119 1343.36 279.139 1353.82 284.426C1365.02 290.467 1373.99 298.02 1379.97 308.593C1386.69 319.165 1390.43 330.493 1390.43 343.331V414.32C1389.68 427.158 1385.95 439.241 1379.97 449.059C1373.24 458.876 1364.28 467.183 1353.82 473.225C1343.36 479.266 1330.65 482.288 1316.46 482.288ZM1326.17 433.2C1341.11 433.2 1353.07 427.914 1362.04 418.096C1371 408.279 1375.49 395.44 1375.49 379.581C1375.49 369.009 1373.24 359.946 1369.51 351.638C1365.02 343.331 1359.79 337.29 1352.32 332.759C1344.85 328.227 1335.88 325.962 1326.17 325.962C1316.46 325.962 1308.24 328.227 1300.77 332.759C1293.29 337.29 1287.32 343.331 1283.58 351.638C1279.1 359.946 1277.6 369.009 1277.6 379.581C1277.6 390.154 1279.85 399.216 1283.58 407.523C1288.06 415.83 1293.29 421.872 1300.77 426.403C1308.24 430.934 1316.46 433.2 1326.17 433.2ZM1373.24 478.512V425.648L1381.46 377.315L1373.24 329.738V281.406H1425.55V478.512H1373.24V478.512Z'
      fill={(props.fill as string) || 'black'}
    />
    <path
      d='M1467.88 478.606V184.079H1521.27V478.606H1467.88Z'
      fill={(props.fill as string) || 'black'}
    />
    <path
      d='M1590.3 249.546C1581.19 249.546 1574.35 246.533 1568.28 240.505C1562.2 234.477 1559.92 227.695 1559.92 218.653C1559.92 210.365 1562.96 202.83 1568.28 196.802C1574.35 190.774 1581.19 187.761 1590.3 187.761C1599.41 187.761 1607 190.774 1612.32 196.802C1617.63 202.83 1620.67 210.365 1620.67 218.653C1620.67 227.695 1617.63 234.477 1612.32 240.505C1606.24 246.533 1599.41 249.546 1590.3 249.546ZM1562.96 478.606V281.946H1616.87V478.606H1562.96Z'
      fill={(props.fill as string) || 'black'}
    />
    <path
      d='M1659.33 478.606V184.079H1712.71V478.606H1659.33Z'
      fill={(props.fill as string) || 'black'}
    />
    <path
      d='M1849.63 482.288C1829.34 482.288 1810.55 477.756 1794.77 468.694C1778.99 459.632 1766.21 447.549 1756.44 432.445C1747.42 416.586 1742.16 399.216 1742.16 379.581C1742.16 359.946 1746.67 342.577 1755.69 326.718C1764.71 311.614 1777.48 298.775 1793.26 289.713C1809.05 280.65 1826.33 276.119 1845.87 276.119C1864.66 276.119 1881.94 280.651 1896.22 288.958C1910.5 297.265 1921.77 309.348 1930.03 324.452C1938.3 339.555 1942.81 356.169 1942.81 375.049C1942.81 378.825 1942.81 381.846 1942.06 385.622C1941.31 389.398 1940.56 393.175 1939.8 398.461L1774.48 399.216V359.19L1915 358.435L1893.21 375.049C1892.46 362.966 1890.21 353.905 1887.2 345.597C1883.44 338.045 1878.18 332.004 1871.42 327.473C1864.66 322.941 1856.39 321.431 1845.87 321.431C1835.35 321.431 1826.33 323.696 1818.06 328.228C1810.55 332.759 1804.54 339.555 1800.03 348.617C1795.52 356.925 1794.02 367.497 1794.02 378.825C1794.02 390.908 1796.27 401.482 1800.78 409.789C1805.29 418.851 1812.05 425.648 1820.32 430.179C1828.58 434.71 1838.35 436.976 1849.63 436.976C1859.39 436.976 1868.41 435.465 1876.68 431.689C1884.95 427.913 1891.71 423.382 1897.72 416.585L1929.28 448.304C1919.51 459.632 1908.24 467.939 1894.71 473.981C1880.44 479.267 1865.41 482.288 1849.63 482.288Z'
      fill={(props.fill as string) || 'black'}
    />
    <path
      d='M2064.68 482.288C2045.12 482.288 2027.07 477.756 2011.28 468.694C1995.48 459.632 1982.69 446.793 1973.67 431.689C1964.64 415.83 1959.38 398.46 1959.38 378.825C1959.38 359.19 1963.89 341.821 1972.92 326.718C1981.94 311.614 1994.73 298.775 2010.52 289.713C2026.32 280.65 2044.37 276.119 2063.93 276.119C2084.23 276.119 2102.29 280.65 2118.08 289.713C2133.88 298.775 2146.66 310.858 2155.69 326.718C2164.71 341.821 2169.23 359.19 2169.23 378.825C2169.23 398.46 2164.71 415.83 2155.69 431.689C2146.66 447.548 2133.88 459.632 2118.08 468.694C2102.29 477.756 2084.23 482.288 2064.68 482.288ZM2063.93 432.445C2073.7 432.445 2082.73 430.179 2090.25 425.648C2097.77 421.117 2103.79 415.075 2108.3 406.768C2112.82 398.461 2114.32 389.398 2114.32 378.825C2114.32 368.253 2112.06 359.19 2107.55 351.638C2103.04 344.086 2097.02 337.29 2089.5 332.759C2081.98 328.227 2072.95 325.962 2063.17 325.962C2053.4 325.962 2044.37 328.227 2036.85 332.759C2029.33 337.29 2023.31 343.331 2018.8 351.638C2014.28 359.19 2012.03 369.008 2012.03 378.825C2012.03 389.398 2014.28 398.461 2018.8 406.768C2023.31 415.075 2029.33 421.117 2036.85 425.648C2045.87 430.179 2054.15 432.445 2063.93 432.445Z'
      fill={(props.fill as string) || 'black'}
    />
    <path
      d='M581.917 560.053L625.629 557.551C708.058 552.546 726.167 526.269 708.683 446.814C708.683 446.814 653.73 491.234 632.498 506.875C588.786 540.033 560.061 533.777 523.217 484.352L457.649 392.384L482.628 502.495C498.864 564.433 514.475 560.053 581.917 560.053Z'
      fill='url(#paint0_radial_1008_14)'
    />
    <path
      d='M487.625 630.75L528.839 646.391C606.272 675.796 633.124 658.903 649.984 578.822C649.984 578.822 581.918 596.966 556.315 602.597C502.612 615.109 478.882 597.591 465.769 537.531L443.288 426.793L421.432 536.905C409.567 601.345 425.179 603.222 487.625 630.75Z'
      fill='url(#paint1_radial_1008_14)'
    />
    <path
      d='M372.099 657.026L403.322 688.308C462.021 746.492 493.244 742.113 541.328 675.795C541.328 675.795 472.013 664.534 445.785 659.529C392.082 649.519 377.095 623.242 389.584 563.181L413.938 453.07L348.994 545.038C312.775 598.217 325.889 606.976 372.099 657.026Z'
      fill='url(#paint2_radial_1008_14)'
    />
    <path
      d='M255.951 633.878L271.563 674.544C301.537 752.122 332.135 760.256 402.699 719.589C402.699 719.589 344 680.8 322.144 665.785C277.183 634.503 274.061 605.098 309.655 554.422L376.472 463.705L279.68 521.264C225.352 556.299 234.095 569.437 255.951 633.878Z'
      fill='url(#paint3_radial_1008_14)'
    />
    <path
      d='M159.156 565.684L156.659 609.479C152.912 692.688 177.266 712.708 258.446 703.949C258.446 703.949 220.354 644.514 206.616 621.991C177.89 575.069 187.881 546.915 240.336 515.633L338.376 459.952L226.598 473.09C162.903 482.475 165.401 498.116 159.156 565.684Z'
      fill='url(#paint4_radial_1008_14)'
    />
    <path
      d='M97.9596 463.706L77.9768 503.121C40.5092 577.571 54.8719 605.724 132.305 630.75C132.305 630.75 121.689 561.304 117.942 535.028C111.073 480.598 131.056 458.701 192.253 451.193L304.656 440.557L197.249 407.399C136.052 389.255 131.68 404.896 97.9596 463.706Z'
      fill='url(#paint5_radial_1008_14)'
    />
    <path
      d='M84.2241 346.087L50.5035 373.614C-13.8159 426.168 -12.5671 458.075 48.0055 512.505C48.0055 512.505 66.7394 444.311 74.2329 419.286C89.8444 366.732 117.321 354.845 176.02 372.989L282.802 408.65L198.5 334.2C148.543 292.908 139.177 305.42 84.2241 346.087Z'
      fill='url(#paint6_radial_1008_14)'
    />
    <path
      d='M119.193 232.221L76.7295 243.482C-3.20137 265.379 -15.0659 294.784 18.0305 369.235C18.0305 369.235 62.3669 314.804 79.8518 294.784C115.446 252.867 145.42 253.492 191.63 294.159L274.683 370.486L227.849 268.508C199.748 210.324 185.386 217.831 119.193 232.221Z'
      fill='url(#paint7_radial_1008_14)'
    />
    <path
      d='M197.251 143.381L154.163 136.499C72.3592 123.986 49.8786 145.884 49.8786 227.842C49.8786 227.842 112.949 195.934 136.679 184.673C186.011 160.899 213.487 173.411 239.09 229.718L284.051 332.948L282.802 220.334C279.68 155.894 263.444 157.145 197.251 143.381Z'
      fill='url(#paint8_radial_1008_14)'
    />
    <path
      d='M304.656 93.3299L267.813 69.5557C197.874 24.5101 168.524 35.7715 135.428 110.222C135.428 110.222 205.992 107.094 232.219 106.468C287.171 105.217 306.529 127.74 307.778 189.052L306.53 301.666L350.866 198.436C374.596 138.375 359.609 133.37 304.656 93.3299Z'
      fill='url(#paint9_radial_1008_14)'
    />
    <path
      d='M423.306 92.079L399.577 55.1666C353.991 -14.2787 322.768 -16.1557 262.195 38.2744C262.195 38.2744 327.764 63.9255 352.118 73.9357C402.699 95.2072 411.441 123.361 387.087 180.293L340.253 282.897L422.682 206.57C468.892 161.524 457.651 150.263 423.306 92.079Z'
      fill='url(#paint10_radial_1008_14)'
    />
    <path
      d='M531.96 138.376L525.091 95.2073C511.353 13.2492 483.876 -1.14032 406.443 23.885C406.443 23.885 455.776 73.9358 473.885 93.3304C511.353 133.371 508.23 162.776 463.269 204.693L378.967 279.144L485.125 242.857C545.698 220.96 539.453 205.944 531.96 138.376Z'
      fill='url(#paint11_radial_1008_14)'
    />
    <path
      d='M612.517 225.964L623.757 183.421C644.989 103.34 625.006 78.3148 543.827 69.5559C543.827 69.5559 568.805 135.247 577.547 160.273C595.657 212.2 580.67 237.851 522.595 257.872L415.188 291.656L526.966 302.292C591.286 306.671 591.91 290.405 612.517 225.964Z'
      fill='url(#paint12_radial_1008_14)'
    />
    <path
      d='M650.608 337.953L678.085 303.543C729.915 238.477 721.797 207.821 651.233 167.155C651.233 167.155 647.486 237.852 644.988 264.129C640.617 319.184 616.263 336.076 555.066 330.446L443.287 317.307L541.328 372.363C598.778 403.019 605.647 388.63 650.608 337.953Z'
      fill='url(#paint13_radial_1008_14)'
    />
    <path
      d='M639.992 456.198L678.709 436.177C752.395 398.013 757.391 366.732 709.932 301.04C709.932 301.04 677.46 363.604 664.971 386.752C638.743 434.926 609.394 440.557 555.69 410.526L458.899 352.968L526.34 443.059C565.681 494.361 578.171 484.351 639.992 456.198Z'
      fill='url(#paint14_radial_1008_14)'
    />
    <path
      d='M581.917 560.053L625.629 557.551C708.058 552.546 726.167 526.269 708.683 446.814C708.683 446.814 653.73 491.234 632.498 506.875C588.786 540.033 560.061 533.777 523.217 484.352L457.649 392.384L482.628 502.495C498.864 564.433 514.475 560.053 581.917 560.053Z'
      fill='url(#paint15_radial_1008_14)'
    />
    <path
      d='M487.625 630.75L528.839 646.391C606.272 675.796 633.124 658.903 649.984 578.822C649.984 578.822 581.918 596.966 556.315 602.597C502.612 615.109 478.882 597.591 465.769 537.531L443.288 426.793L421.432 536.905C409.567 601.345 425.179 603.222 487.625 630.75Z'
      fill='url(#paint16_radial_1008_14)'
    />
    <path
      d='M372.099 657.026L403.322 688.308C462.021 746.492 493.244 742.113 541.328 675.795C541.328 675.795 472.013 664.534 445.785 659.529C392.082 649.519 377.095 623.242 389.584 563.181L413.938 453.07L348.994 545.038C312.775 598.217 325.889 606.976 372.099 657.026Z'
      fill='url(#paint17_radial_1008_14)'
    />
    <path
      d='M255.951 633.878L271.563 674.544C301.537 752.122 332.135 760.256 402.699 719.589C402.699 719.589 344 680.8 322.144 665.785C277.183 634.503 274.061 605.098 309.655 554.422L376.472 463.705L279.68 521.264C225.352 556.299 234.095 569.437 255.951 633.878Z'
      fill='url(#paint18_radial_1008_14)'
    />
    <path
      d='M159.156 565.684L156.659 609.479C152.912 692.688 177.266 712.708 258.446 703.949C258.446 703.949 220.354 644.514 206.616 621.991C177.89 575.069 187.881 546.915 240.336 515.633L338.376 459.952L226.598 473.09C162.903 482.475 165.401 498.116 159.156 565.684Z'
      fill='url(#paint19_radial_1008_14)'
    />
    <path
      d='M97.9596 463.706L77.9768 503.121C40.5092 577.571 54.8719 605.724 132.305 630.75C132.305 630.75 121.689 561.304 117.942 535.028C111.073 480.598 131.056 458.701 192.253 451.193L304.656 440.557L197.249 407.399C136.052 389.255 131.68 404.896 97.9596 463.706Z'
      fill='url(#paint20_radial_1008_14)'
    />
    <path
      d='M84.2241 346.087L50.5035 373.614C-13.8159 426.168 -12.5671 458.075 48.0055 512.505C48.0055 512.505 66.7394 444.311 74.2329 419.286C89.8444 366.732 117.321 354.845 176.02 372.989L282.802 408.65L198.5 334.2C148.543 292.908 139.177 305.42 84.2241 346.087Z'
      fill='url(#paint21_radial_1008_14)'
    />
    <path
      d='M119.193 232.221L76.7295 243.482C-3.20137 265.379 -15.0659 294.784 18.0305 369.235C18.0305 369.235 62.3669 314.804 79.8518 294.784C115.446 252.867 145.42 253.492 191.63 294.159L274.683 370.486L227.849 268.508C199.748 210.324 185.386 217.831 119.193 232.221Z'
      fill='url(#paint22_radial_1008_14)'
    />
    <path
      d='M197.251 143.381L154.163 136.499C72.3592 123.986 49.8786 145.884 49.8786 227.842C49.8786 227.842 112.949 195.934 136.679 184.673C186.011 160.899 213.487 173.411 239.09 229.718L284.051 332.948L282.802 220.334C279.68 155.894 263.444 157.145 197.251 143.381Z'
      fill='url(#paint23_radial_1008_14)'
    />
    <path
      d='M304.656 93.3299L267.813 69.5557C197.874 24.5101 168.524 35.7715 135.428 110.222C135.428 110.222 205.992 107.094 232.219 106.468C287.171 105.217 306.529 127.74 307.778 189.052L306.53 301.666L350.866 198.436C374.596 138.375 359.609 133.37 304.656 93.3299Z'
      fill='url(#paint24_radial_1008_14)'
    />
    <path
      d='M423.306 92.079L399.577 55.1666C353.991 -14.2787 322.768 -16.1557 262.195 38.2744C262.195 38.2744 327.764 63.9255 352.118 73.9357C402.699 95.2072 411.441 123.361 387.087 180.293L340.253 282.897L422.682 206.57C468.892 161.524 457.651 150.263 423.306 92.079Z'
      fill='url(#paint25_radial_1008_14)'
    />
    <path
      d='M531.96 138.376L525.091 95.2073C511.353 13.2492 483.876 -1.14032 406.443 23.885C406.443 23.885 455.776 73.9358 473.885 93.3304C511.353 133.371 508.23 162.776 463.269 204.693L378.967 279.144L485.125 242.857C545.698 220.96 539.453 205.944 531.96 138.376Z'
      fill='url(#paint26_radial_1008_14)'
    />
    <path
      d='M612.517 225.964L623.757 183.421C644.989 103.34 625.006 78.3148 543.827 69.5559C543.827 69.5559 568.805 135.247 577.547 160.273C595.657 212.2 580.67 237.851 522.595 257.872L415.188 291.656L526.966 302.292C591.286 306.671 591.91 290.405 612.517 225.964Z'
      fill='url(#paint27_radial_1008_14)'
    />
    <path
      d='M650.608 337.953L678.085 303.543C729.915 238.477 721.797 207.821 651.233 167.155C651.233 167.155 647.486 237.852 644.988 264.129C640.617 319.184 616.263 336.076 555.066 330.446L443.287 317.307L541.328 372.363C598.778 403.019 605.647 388.63 650.608 337.953Z'
      fill='url(#paint28_radial_1008_14)'
    />
    <path
      d='M639.992 456.198L678.709 436.177C752.395 398.013 757.391 366.732 709.932 301.04C709.932 301.04 677.46 363.604 664.971 386.752C638.743 434.926 609.394 440.557 555.69 410.526L458.899 352.968L526.34 443.059C565.681 494.361 578.171 484.351 639.992 456.198Z'
      fill='url(#paint29_radial_1008_14)'
    />
    <defs>
      <radialGradient
        cx='0'
        cy='0'
        gradientTransform='translate(145.163) rotate(56.6947) scale(889.83 1178.15)'
        gradientUnits='userSpaceOnUse'
        id='paint0_radial_1008_14'
        r='1'
      >
        <stop stopColor='#FFAF27' />
        <stop offset='0.564104' stopColor='#DE00B2' />
        <stop offset='1' stopColor='#2F21FA' />
      </radialGradient>
      <radialGradient
        cx='0'
        cy='0'
        gradientTransform='translate(145.163) rotate(56.6947) scale(889.83 1178.15)'
        gradientUnits='userSpaceOnUse'
        id='paint1_radial_1008_14'
        r='1'
      >
        <stop stopColor='#FFAF27' />
        <stop offset='0.564104' stopColor='#DE00B2' />
        <stop offset='1' stopColor='#2F21FA' />
      </radialGradient>
      <radialGradient
        cx='0'
        cy='0'
        gradientTransform='translate(145.163) rotate(56.6947) scale(889.83 1178.15)'
        gradientUnits='userSpaceOnUse'
        id='paint2_radial_1008_14'
        r='1'
      >
        <stop stopColor='#FFAF27' />
        <stop offset='0.564104' stopColor='#DE00B2' />
        <stop offset='1' stopColor='#2F21FA' />
      </radialGradient>
      <radialGradient
        cx='0'
        cy='0'
        gradientTransform='translate(145.163) rotate(56.6947) scale(889.83 1178.15)'
        gradientUnits='userSpaceOnUse'
        id='paint3_radial_1008_14'
        r='1'
      >
        <stop stopColor='#FFAF27' />
        <stop offset='0.564104' stopColor='#DE00B2' />
        <stop offset='1' stopColor='#2F21FA' />
      </radialGradient>
      <radialGradient
        cx='0'
        cy='0'
        gradientTransform='translate(145.163) rotate(56.6947) scale(889.83 1178.15)'
        gradientUnits='userSpaceOnUse'
        id='paint4_radial_1008_14'
        r='1'
      >
        <stop stopColor='#FFAF27' />
        <stop offset='0.564104' stopColor='#DE00B2' />
        <stop offset='1' stopColor='#2F21FA' />
      </radialGradient>
      <radialGradient
        cx='0'
        cy='0'
        gradientTransform='translate(145.163) rotate(56.6947) scale(889.83 1178.15)'
        gradientUnits='userSpaceOnUse'
        id='paint5_radial_1008_14'
        r='1'
      >
        <stop stopColor='#FFAF27' />
        <stop offset='0.564104' stopColor='#DE00B2' />
        <stop offset='1' stopColor='#2F21FA' />
      </radialGradient>
      <radialGradient
        cx='0'
        cy='0'
        gradientTransform='translate(145.163) rotate(56.6947) scale(889.83 1178.15)'
        gradientUnits='userSpaceOnUse'
        id='paint6_radial_1008_14'
        r='1'
      >
        <stop stopColor='#FFAF27' />
        <stop offset='0.564104' stopColor='#DE00B2' />
        <stop offset='1' stopColor='#2F21FA' />
      </radialGradient>
      <radialGradient
        cx='0'
        cy='0'
        gradientTransform='translate(145.163) rotate(56.6947) scale(889.83 1178.15)'
        gradientUnits='userSpaceOnUse'
        id='paint7_radial_1008_14'
        r='1'
      >
        <stop stopColor='#FFAF27' />
        <stop offset='0.564104' stopColor='#DE00B2' />
        <stop offset='1' stopColor='#2F21FA' />
      </radialGradient>
      <radialGradient
        cx='0'
        cy='0'
        gradientTransform='translate(145.163) rotate(56.6947) scale(889.83 1178.15)'
        gradientUnits='userSpaceOnUse'
        id='paint8_radial_1008_14'
        r='1'
      >
        <stop stopColor='#FFAF27' />
        <stop offset='0.564104' stopColor='#DE00B2' />
        <stop offset='1' stopColor='#2F21FA' />
      </radialGradient>
      <radialGradient
        cx='0'
        cy='0'
        gradientTransform='translate(145.163) rotate(56.6947) scale(889.83 1178.15)'
        gradientUnits='userSpaceOnUse'
        id='paint9_radial_1008_14'
        r='1'
      >
        <stop stopColor='#FFAF27' />
        <stop offset='0.564104' stopColor='#DE00B2' />
        <stop offset='1' stopColor='#2F21FA' />
      </radialGradient>
      <radialGradient
        cx='0'
        cy='0'
        gradientTransform='translate(145.163) rotate(56.6947) scale(889.83 1178.15)'
        gradientUnits='userSpaceOnUse'
        id='paint10_radial_1008_14'
        r='1'
      >
        <stop stopColor='#FFAF27' />
        <stop offset='0.564104' stopColor='#DE00B2' />
        <stop offset='1' stopColor='#2F21FA' />
      </radialGradient>
      <radialGradient
        cx='0'
        cy='0'
        gradientTransform='translate(145.163) rotate(56.6947) scale(889.83 1178.15)'
        gradientUnits='userSpaceOnUse'
        id='paint11_radial_1008_14'
        r='1'
      >
        <stop stopColor='#FFAF27' />
        <stop offset='0.564104' stopColor='#DE00B2' />
        <stop offset='1' stopColor='#2F21FA' />
      </radialGradient>
      <radialGradient
        cx='0'
        cy='0'
        gradientTransform='translate(145.163) rotate(56.6947) scale(889.83 1178.15)'
        gradientUnits='userSpaceOnUse'
        id='paint12_radial_1008_14'
        r='1'
      >
        <stop stopColor='#FFAF27' />
        <stop offset='0.564104' stopColor='#DE00B2' />
        <stop offset='1' stopColor='#2F21FA' />
      </radialGradient>
      <radialGradient
        cx='0'
        cy='0'
        gradientTransform='translate(145.163) rotate(56.6947) scale(889.83 1178.15)'
        gradientUnits='userSpaceOnUse'
        id='paint13_radial_1008_14'
        r='1'
      >
        <stop stopColor='#FFAF27' />
        <stop offset='0.564104' stopColor='#DE00B2' />
        <stop offset='1' stopColor='#2F21FA' />
      </radialGradient>
      <radialGradient
        cx='0'
        cy='0'
        gradientTransform='translate(145.163) rotate(56.6947) scale(889.83 1178.15)'
        gradientUnits='userSpaceOnUse'
        id='paint14_radial_1008_14'
        r='1'
      >
        <stop stopColor='#FFAF27' />
        <stop offset='0.564104' stopColor='#DE00B2' />
        <stop offset='1' stopColor='#2F21FA' />
      </radialGradient>
      <radialGradient
        cx='0'
        cy='0'
        gradientTransform='translate(145.163) rotate(56.6947) scale(889.83 1178.15)'
        gradientUnits='userSpaceOnUse'
        id='paint15_radial_1008_14'
        r='1'
      >
        <stop stopColor='#FFAF27' />
        <stop offset='0.564104' stopColor='#DE00B2' />
        <stop offset='1' stopColor='#2F21FA' />
      </radialGradient>
      <radialGradient
        cx='0'
        cy='0'
        gradientTransform='translate(145.163) rotate(56.6947) scale(889.83 1178.15)'
        gradientUnits='userSpaceOnUse'
        id='paint16_radial_1008_14'
        r='1'
      >
        <stop stopColor='#FFAF27' />
        <stop offset='0.564104' stopColor='#DE00B2' />
        <stop offset='1' stopColor='#2F21FA' />
      </radialGradient>
      <radialGradient
        cx='0'
        cy='0'
        gradientTransform='translate(145.163) rotate(56.6947) scale(889.83 1178.15)'
        gradientUnits='userSpaceOnUse'
        id='paint17_radial_1008_14'
        r='1'
      >
        <stop stopColor='#FFAF27' />
        <stop offset='0.564104' stopColor='#DE00B2' />
        <stop offset='1' stopColor='#2F21FA' />
      </radialGradient>
      <radialGradient
        cx='0'
        cy='0'
        gradientTransform='translate(145.163) rotate(56.6947) scale(889.83 1178.15)'
        gradientUnits='userSpaceOnUse'
        id='paint18_radial_1008_14'
        r='1'
      >
        <stop stopColor='#FFAF27' />
        <stop offset='0.564104' stopColor='#DE00B2' />
        <stop offset='1' stopColor='#2F21FA' />
      </radialGradient>
      <radialGradient
        cx='0'
        cy='0'
        gradientTransform='translate(145.163) rotate(56.6947) scale(889.83 1178.15)'
        gradientUnits='userSpaceOnUse'
        id='paint19_radial_1008_14'
        r='1'
      >
        <stop stopColor='#FFAF27' />
        <stop offset='0.564104' stopColor='#DE00B2' />
        <stop offset='1' stopColor='#2F21FA' />
      </radialGradient>
      <radialGradient
        cx='0'
        cy='0'
        gradientTransform='translate(145.163) rotate(56.6947) scale(889.83 1178.15)'
        gradientUnits='userSpaceOnUse'
        id='paint20_radial_1008_14'
        r='1'
      >
        <stop stopColor='#FFAF27' />
        <stop offset='0.564104' stopColor='#DE00B2' />
        <stop offset='1' stopColor='#2F21FA' />
      </radialGradient>
      <radialGradient
        cx='0'
        cy='0'
        gradientTransform='translate(145.163) rotate(56.6947) scale(889.83 1178.15)'
        gradientUnits='userSpaceOnUse'
        id='paint21_radial_1008_14'
        r='1'
      >
        <stop stopColor='#FFAF27' />
        <stop offset='0.564104' stopColor='#DE00B2' />
        <stop offset='1' stopColor='#2F21FA' />
      </radialGradient>
      <radialGradient
        cx='0'
        cy='0'
        gradientTransform='translate(145.163) rotate(56.6947) scale(889.83 1178.15)'
        gradientUnits='userSpaceOnUse'
        id='paint22_radial_1008_14'
        r='1'
      >
        <stop stopColor='#FFAF27' />
        <stop offset='0.564104' stopColor='#DE00B2' />
        <stop offset='1' stopColor='#2F21FA' />
      </radialGradient>
      <radialGradient
        cx='0'
        cy='0'
        gradientTransform='translate(145.163) rotate(56.6947) scale(889.83 1178.15)'
        gradientUnits='userSpaceOnUse'
        id='paint23_radial_1008_14'
        r='1'
      >
        <stop stopColor='#FFAF27' />
        <stop offset='0.564104' stopColor='#DE00B2' />
        <stop offset='1' stopColor='#2F21FA' />
      </radialGradient>
      <radialGradient
        cx='0'
        cy='0'
        gradientTransform='translate(145.163) rotate(56.6947) scale(889.83 1178.15)'
        gradientUnits='userSpaceOnUse'
        id='paint24_radial_1008_14'
        r='1'
      >
        <stop stopColor='#FFAF27' />
        <stop offset='0.564104' stopColor='#DE00B2' />
        <stop offset='1' stopColor='#2F21FA' />
      </radialGradient>
      <radialGradient
        cx='0'
        cy='0'
        gradientTransform='translate(145.163) rotate(56.6947) scale(889.83 1178.15)'
        gradientUnits='userSpaceOnUse'
        id='paint25_radial_1008_14'
        r='1'
      >
        <stop stopColor='#FFAF27' />
        <stop offset='0.564104' stopColor='#DE00B2' />
        <stop offset='1' stopColor='#2F21FA' />
      </radialGradient>
      <radialGradient
        cx='0'
        cy='0'
        gradientTransform='translate(145.163) rotate(56.6947) scale(889.83 1178.15)'
        gradientUnits='userSpaceOnUse'
        id='paint26_radial_1008_14'
        r='1'
      >
        <stop stopColor='#FFAF27' />
        <stop offset='0.564104' stopColor='#DE00B2' />
        <stop offset='1' stopColor='#2F21FA' />
      </radialGradient>
      <radialGradient
        cx='0'
        cy='0'
        gradientTransform='translate(145.163) rotate(56.6947) scale(889.83 1178.15)'
        gradientUnits='userSpaceOnUse'
        id='paint27_radial_1008_14'
        r='1'
      >
        <stop stopColor='#FFAF27' />
        <stop offset='0.564104' stopColor='#DE00B2' />
        <stop offset='1' stopColor='#2F21FA' />
      </radialGradient>
      <radialGradient
        cx='0'
        cy='0'
        gradientTransform='translate(145.163) rotate(56.6947) scale(889.83 1178.15)'
        gradientUnits='userSpaceOnUse'
        id='paint28_radial_1008_14'
        r='1'
      >
        <stop stopColor='#FFAF27' />
        <stop offset='0.564104' stopColor='#DE00B2' />
        <stop offset='1' stopColor='#2F21FA' />
      </radialGradient>
      <radialGradient
        cx='0'
        cy='0'
        gradientTransform='translate(145.163) rotate(56.6947) scale(889.83 1178.15)'
        gradientUnits='userSpaceOnUse'
        id='paint29_radial_1008_14'
        r='1'
      >
        <stop stopColor='#FFAF27' />
        <stop offset='0.564104' stopColor='#DE00B2' />
        <stop offset='1' stopColor='#2F21FA' />
      </radialGradient>
    </defs>
  </svg>
);

export const Logo = (props: Record<string, string | number>) => (
  <svg
    fill='none'
    height='100%'
    viewBox='0 0 741 744'
    width='100%'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='m581.917 560.053 43.712-2.502c82.429-5.005 100.538-31.282 83.054-110.737 0 0-54.953 44.42-76.185 60.061-43.712 33.158-72.437 26.902-109.281-22.523l-65.568-91.968 24.979 110.111c16.236 61.938 31.847 57.558 99.289 57.558Z'
      fill={(props.fill as string) || 'url(#paint0_radial_1008_23)'}
    />
    <path
      d='m487.625 630.75 41.214 15.641c77.433 29.405 104.285 12.512 121.145-67.569 0 0-68.066 18.144-93.669 23.774-53.703 12.513-77.433-5.005-90.546-65.065l-22.481-110.738-21.856 110.112c-11.865 64.44 3.747 66.317 66.193 93.845Z'
      fill={(props.fill as string) || 'url(#paint1_radial_1008_23)'}
    />
    <path
      d='m372.099 657.026 31.223 31.282c58.699 58.184 89.922 53.805 138.006-12.513 0 0-69.315-11.261-95.543-16.266-53.703-10.01-68.69-36.287-56.201-96.348l24.354-110.111-64.944 91.968c-36.219 53.179-23.105 61.938 23.105 111.988Z'
      fill={(props.fill as string) || 'url(#paint2_radial_1008_23)'}
    />
    <path
      d='m255.951 633.878 15.611 40.666c29.975 77.578 60.573 85.712 131.137 45.045 0 0-58.699-38.789-80.555-53.804-44.961-31.282-48.083-60.687-12.489-111.363l66.817-90.717-96.792 57.559c-54.328 35.035-45.585 48.173-23.729 112.614Z'
      fill={(props.fill as string) || 'url(#paint3_radial_1008_23)'}
    />
    <path
      d='m159.156 565.684-2.497 43.795c-3.747 83.209 20.607 103.229 101.787 94.47 0 0-38.092-59.435-51.83-81.958-28.726-46.922-18.735-75.076 33.72-106.358l98.04-55.681-111.778 13.138c-63.695 9.385-61.197 25.026-67.442 92.594Z'
      fill={(props.fill as string) || 'url(#paint4_radial_1008_23)'}
    />
    <path
      d='m97.9596 463.706-19.9828 39.415c-37.4676 74.45-23.1049 102.603 54.3282 127.629 0 0-10.616-69.445-14.363-95.722-6.869-54.43 13.114-76.327 74.311-83.835l112.403-10.636-107.407-33.158c-61.197-18.144-65.569-2.503-99.2894 56.307Z'
      fill={(props.fill as string) || 'url(#paint5_radial_1008_23)'}
    />
    <path
      d='m84.2241 346.087-33.7206 27.527c-64.3194 52.554-63.0706 84.461-2.498 138.891 0 0 18.7339-68.194 26.2274-93.219 15.6115-52.554 43.0881-64.441 101.7871-46.297l106.782 35.661L198.5 334.2c-49.957-41.292-59.323-28.78-114.2759 11.887Z'
      fill={(props.fill as string) || 'url(#paint6_radial_1008_23)'}
    />
    <path
      d='m119.193 232.221-42.4635 11.261c-79.93087 21.897-91.7954 51.302-58.699 125.753 0 0 44.3364-54.431 61.8213-74.451 35.5942-41.917 65.5682-41.292 111.7782-.625l83.053 76.327-46.834-101.978c-28.101-58.184-42.463-50.677-108.656-36.287Z'
      fill={(props.fill as string) || 'url(#paint7_radial_1008_23)'}
    />
    <path
      d='m197.251 143.381-43.088-6.882c-81.8038-12.513-104.2844 9.385-104.2844 91.343 0 0 63.0704-31.908 86.8004-43.169 49.332-23.774 76.808-11.262 102.411 45.045l44.961 103.23-1.249-112.614c-3.122-64.44-19.358-63.189-85.551-76.953Z'
      fill={(props.fill as string) || 'url(#paint8_radial_1008_23)'}
    />
    <path
      d='m304.656 93.3299-36.843-23.7742c-69.939-45.0456-99.289-33.7842-132.385 40.6663 0 0 70.564-3.128 96.791-3.754 54.952-1.251 74.31 21.272 75.559 82.584l-1.248 112.614 44.336-103.23c23.73-60.061 8.743-65.066-46.21-105.1061Z'
      fill={(props.fill as string) || 'url(#paint9_radial_1008_23)'}
    />
    <path
      d='m423.306 92.079-23.729-36.9124c-45.586-69.4453-76.809-71.3223-137.382-16.8922 0 0 65.569 25.6511 89.923 35.6613 50.581 21.2715 59.323 49.4253 34.969 106.3573l-46.834 102.604 82.429-76.327c46.21-45.046 34.969-56.307.624-114.491Z'
      fill={(props.fill as string) || 'url(#paint10_radial_1008_23)'}
    />
    <path
      d='m531.96 138.376-6.869-43.1687C511.353 13.2492 483.876-1.14032 406.443 23.885c0 0 49.333 50.0508 67.442 69.4454 37.468 40.0406 34.345 69.4456-10.616 111.3626l-84.302 74.451 106.158-36.287c60.573-21.897 54.328-36.913 46.835-104.481Z'
      fill={(props.fill as string) || 'url(#paint11_radial_1008_23)'}
    />
    <path
      d='m612.517 225.964 11.24-42.543c21.232-80.081 1.249-105.1062-79.93-113.8651 0 0 24.978 65.6911 33.72 90.7171 18.11 51.927 3.123 77.578-54.952 97.599l-107.407 33.784 111.778 10.636c64.32 4.379 64.944-11.887 85.551-76.328Z'
      fill={(props.fill as string) || 'url(#paint12_radial_1008_23)'}
    />
    <path
      d='m650.608 337.953 27.477-34.41c51.83-65.066 43.712-95.722-26.852-136.388 0 0-3.747 70.697-6.245 96.974-4.371 55.055-28.725 71.947-89.922 66.317l-111.779-13.139 98.041 55.056c57.45 30.656 64.319 16.267 109.28-34.41Z'
      fill={(props.fill as string) || 'url(#paint13_radial_1008_23)'}
    />
    <path
      d='m639.992 456.198 38.717-20.021c73.686-38.164 78.682-69.445 31.223-135.137 0 0-32.472 62.564-44.961 85.712-26.228 48.174-55.577 53.805-109.281 23.774l-96.791-57.558 67.441 90.091c39.341 51.302 51.831 41.292 113.652 13.139Z'
      fill={(props.fill as string) || 'url(#paint14_radial_1008_23)'}
    />
    <path
      d='m581.917 560.053 43.712-2.502c82.429-5.005 100.538-31.282 83.054-110.737 0 0-54.953 44.42-76.185 60.061-43.712 33.158-72.437 26.902-109.281-22.523l-65.568-91.968 24.979 110.111c16.236 61.938 31.847 57.558 99.289 57.558Z'
      fill={(props.fill as string) || 'url(#paint15_radial_1008_23)'}
    />
    <path
      d='m487.625 630.75 41.214 15.641c77.433 29.405 104.285 12.512 121.145-67.569 0 0-68.066 18.144-93.669 23.774-53.703 12.513-77.433-5.005-90.546-65.065l-22.481-110.738-21.856 110.112c-11.865 64.44 3.747 66.317 66.193 93.845Z'
      fill={(props.fill as string) || 'url(#paint16_radial_1008_23)'}
    />
    <path
      d='m372.099 657.026 31.223 31.282c58.699 58.184 89.922 53.805 138.006-12.513 0 0-69.315-11.261-95.543-16.266-53.703-10.01-68.69-36.287-56.201-96.348l24.354-110.111-64.944 91.968c-36.219 53.179-23.105 61.938 23.105 111.988Z'
      fill={(props.fill as string) || 'url(#paint17_radial_1008_23)'}
    />
    <path
      d='m255.951 633.878 15.611 40.666c29.975 77.578 60.573 85.712 131.137 45.045 0 0-58.699-38.789-80.555-53.804-44.961-31.282-48.083-60.687-12.489-111.363l66.817-90.717-96.792 57.559c-54.328 35.035-45.585 48.173-23.729 112.614Z'
      fill={(props.fill as string) || 'url(#paint18_radial_1008_23)'}
    />
    <path
      d='m159.156 565.684-2.497 43.795c-3.747 83.209 20.607 103.229 101.787 94.47 0 0-38.092-59.435-51.83-81.958-28.726-46.922-18.735-75.076 33.72-106.358l98.04-55.681-111.778 13.138c-63.695 9.385-61.197 25.026-67.442 92.594Z'
      fill={(props.fill as string) || 'url(#paint19_radial_1008_23)'}
    />
    <path
      d='m97.9596 463.706-19.9828 39.415c-37.4676 74.45-23.1049 102.603 54.3282 127.629 0 0-10.616-69.445-14.363-95.722-6.869-54.43 13.114-76.327 74.311-83.835l112.403-10.636-107.407-33.158c-61.197-18.144-65.569-2.503-99.2894 56.307Z'
      fill={(props.fill as string) || 'url(#paint20_radial_1008_23)'}
    />
    <path
      d='m84.2241 346.087-33.7206 27.527c-64.3194 52.554-63.0706 84.461-2.498 138.891 0 0 18.7339-68.194 26.2274-93.219 15.6115-52.554 43.0881-64.441 101.7871-46.297l106.782 35.661L198.5 334.2c-49.957-41.292-59.323-28.78-114.2759 11.887Z'
      fill={(props.fill as string) || 'url(#paint21_radial_1008_23)'}
    />
    <path
      d='m119.193 232.221-42.4635 11.261c-79.93087 21.897-91.7954 51.302-58.699 125.753 0 0 44.3364-54.431 61.8213-74.451 35.5942-41.917 65.5682-41.292 111.7782-.625l83.053 76.327-46.834-101.978c-28.101-58.184-42.463-50.677-108.656-36.287Z'
      fill={(props.fill as string) || 'url(#paint22_radial_1008_23)'}
    />
    <path
      d='m197.251 143.381-43.088-6.882c-81.8038-12.513-104.2844 9.385-104.2844 91.343 0 0 63.0704-31.908 86.8004-43.169 49.332-23.774 76.808-11.262 102.411 45.045l44.961 103.23-1.249-112.614c-3.122-64.44-19.358-63.189-85.551-76.953Z'
      fill={(props.fill as string) || 'url(#paint23_radial_1008_23)'}
    />
    <path
      d='m304.656 93.3299-36.843-23.7742c-69.939-45.0456-99.289-33.7842-132.385 40.6663 0 0 70.564-3.128 96.791-3.754 54.952-1.251 74.31 21.272 75.559 82.584l-1.248 112.614 44.336-103.23c23.73-60.061 8.743-65.066-46.21-105.1061Z'
      fill={(props.fill as string) || 'url(#paint24_radial_1008_23)'}
    />
    <path
      d='m423.306 92.079-23.729-36.9124c-45.586-69.4453-76.809-71.3223-137.382-16.8922 0 0 65.569 25.6511 89.923 35.6613 50.581 21.2715 59.323 49.4253 34.969 106.3573l-46.834 102.604 82.429-76.327c46.21-45.046 34.969-56.307.624-114.491Z'
      fill={(props.fill as string) || 'url(#paint25_radial_1008_23)'}
    />
    <path
      d='m531.96 138.376-6.869-43.1687C511.353 13.2492 483.876-1.14032 406.443 23.885c0 0 49.333 50.0508 67.442 69.4454 37.468 40.0406 34.345 69.4456-10.616 111.3626l-84.302 74.451 106.158-36.287c60.573-21.897 54.328-36.913 46.835-104.481Z'
      fill={(props.fill as string) || 'url(#paint26_radial_1008_23)'}
    />
    <path
      d='m612.517 225.964 11.24-42.543c21.232-80.081 1.249-105.1062-79.93-113.8651 0 0 24.978 65.6911 33.72 90.7171 18.11 51.927 3.123 77.578-54.952 97.599l-107.407 33.784 111.778 10.636c64.32 4.379 64.944-11.887 85.551-76.328Z'
      fill={(props.fill as string) || 'url(#paint27_radial_1008_23)'}
    />
    <path
      d='m650.608 337.953 27.477-34.41c51.83-65.066 43.712-95.722-26.852-136.388 0 0-3.747 70.697-6.245 96.974-4.371 55.055-28.725 71.947-89.922 66.317l-111.779-13.139 98.041 55.056c57.45 30.656 64.319 16.267 109.28-34.41Z'
      fill={(props.fill as string) || 'url(#paint28_radial_1008_23)'}
    />
    <path
      d='m639.992 456.198 38.717-20.021c73.686-38.164 78.682-69.445 31.223-135.137 0 0-32.472 62.564-44.961 85.712-26.228 48.174-55.577 53.805-109.281 23.774l-96.791-57.558 67.441 90.091c39.341 51.302 51.831 41.292 113.652 13.139Z'
      fill={(props.fill as string) || 'url(#paint29_radial_1008_23)'}
    />
    <defs>
      <radialGradient
        cx='0'
        cy='0'
        gradientTransform='translate(145.163) rotate(56.6947) scale(889.83 1178.15)'
        gradientUnits='userSpaceOnUse'
        id='paint0_radial_1008_23'
        r='1'
      >
        <stop stopColor='#FFAF27' />
        <stop offset='0.564104' stopColor='#DE00B2' />
        <stop offset='1' stopColor='#2F21FA' />
      </radialGradient>
      <radialGradient
        cx='0'
        cy='0'
        gradientTransform='translate(145.163) rotate(56.6947) scale(889.83 1178.15)'
        gradientUnits='userSpaceOnUse'
        id='paint1_radial_1008_23'
        r='1'
      >
        <stop stopColor='#FFAF27' />
        <stop offset='0.564104' stopColor='#DE00B2' />
        <stop offset='1' stopColor='#2F21FA' />
      </radialGradient>
      <radialGradient
        cx='0'
        cy='0'
        gradientTransform='translate(145.163) rotate(56.6947) scale(889.83 1178.15)'
        gradientUnits='userSpaceOnUse'
        id='paint2_radial_1008_23'
        r='1'
      >
        <stop stopColor='#FFAF27' />
        <stop offset='0.564104' stopColor='#DE00B2' />
        <stop offset='1' stopColor='#2F21FA' />
      </radialGradient>
      <radialGradient
        cx='0'
        cy='0'
        gradientTransform='translate(145.163) rotate(56.6947) scale(889.83 1178.15)'
        gradientUnits='userSpaceOnUse'
        id='paint3_radial_1008_23'
        r='1'
      >
        <stop stopColor='#FFAF27' />
        <stop offset='0.564104' stopColor='#DE00B2' />
        <stop offset='1' stopColor='#2F21FA' />
      </radialGradient>
      <radialGradient
        cx='0'
        cy='0'
        gradientTransform='translate(145.163) rotate(56.6947) scale(889.83 1178.15)'
        gradientUnits='userSpaceOnUse'
        id='paint4_radial_1008_23'
        r='1'
      >
        <stop stopColor='#FFAF27' />
        <stop offset='0.564104' stopColor='#DE00B2' />
        <stop offset='1' stopColor='#2F21FA' />
      </radialGradient>
      <radialGradient
        cx='0'
        cy='0'
        gradientTransform='translate(145.163) rotate(56.6947) scale(889.83 1178.15)'
        gradientUnits='userSpaceOnUse'
        id='paint5_radial_1008_23'
        r='1'
      >
        <stop stopColor='#FFAF27' />
        <stop offset='0.564104' stopColor='#DE00B2' />
        <stop offset='1' stopColor='#2F21FA' />
      </radialGradient>
      <radialGradient
        cx='0'
        cy='0'
        gradientTransform='translate(145.163) rotate(56.6947) scale(889.83 1178.15)'
        gradientUnits='userSpaceOnUse'
        id='paint6_radial_1008_23'
        r='1'
      >
        <stop stopColor='#FFAF27' />
        <stop offset='0.564104' stopColor='#DE00B2' />
        <stop offset='1' stopColor='#2F21FA' />
      </radialGradient>
      <radialGradient
        cx='0'
        cy='0'
        gradientTransform='translate(145.163) rotate(56.6947) scale(889.83 1178.15)'
        gradientUnits='userSpaceOnUse'
        id='paint7_radial_1008_23'
        r='1'
      >
        <stop stopColor='#FFAF27' />
        <stop offset='0.564104' stopColor='#DE00B2' />
        <stop offset='1' stopColor='#2F21FA' />
      </radialGradient>
      <radialGradient
        cx='0'
        cy='0'
        gradientTransform='translate(145.163) rotate(56.6947) scale(889.83 1178.15)'
        gradientUnits='userSpaceOnUse'
        id='paint8_radial_1008_23'
        r='1'
      >
        <stop stopColor='#FFAF27' />
        <stop offset='0.564104' stopColor='#DE00B2' />
        <stop offset='1' stopColor='#2F21FA' />
      </radialGradient>
      <radialGradient
        cx='0'
        cy='0'
        gradientTransform='translate(145.163) rotate(56.6947) scale(889.83 1178.15)'
        gradientUnits='userSpaceOnUse'
        id='paint9_radial_1008_23'
        r='1'
      >
        <stop stopColor='#FFAF27' />
        <stop offset='0.564104' stopColor='#DE00B2' />
        <stop offset='1' stopColor='#2F21FA' />
      </radialGradient>
      <radialGradient
        cx='0'
        cy='0'
        gradientTransform='translate(145.163) rotate(56.6947) scale(889.83 1178.15)'
        gradientUnits='userSpaceOnUse'
        id='paint10_radial_1008_23'
        r='1'
      >
        <stop stopColor='#FFAF27' />
        <stop offset='0.564104' stopColor='#DE00B2' />
        <stop offset='1' stopColor='#2F21FA' />
      </radialGradient>
      <radialGradient
        cx='0'
        cy='0'
        gradientTransform='translate(145.163) rotate(56.6947) scale(889.83 1178.15)'
        gradientUnits='userSpaceOnUse'
        id='paint11_radial_1008_23'
        r='1'
      >
        <stop stopColor='#FFAF27' />
        <stop offset='0.564104' stopColor='#DE00B2' />
        <stop offset='1' stopColor='#2F21FA' />
      </radialGradient>
      <radialGradient
        cx='0'
        cy='0'
        gradientTransform='translate(145.163) rotate(56.6947) scale(889.83 1178.15)'
        gradientUnits='userSpaceOnUse'
        id='paint12_radial_1008_23'
        r='1'
      >
        <stop stopColor='#FFAF27' />
        <stop offset='0.564104' stopColor='#DE00B2' />
        <stop offset='1' stopColor='#2F21FA' />
      </radialGradient>
      <radialGradient
        cx='0'
        cy='0'
        gradientTransform='translate(145.163) rotate(56.6947) scale(889.83 1178.15)'
        gradientUnits='userSpaceOnUse'
        id='paint13_radial_1008_23'
        r='1'
      >
        <stop stopColor='#FFAF27' />
        <stop offset='0.564104' stopColor='#DE00B2' />
        <stop offset='1' stopColor='#2F21FA' />
      </radialGradient>
      <radialGradient
        cx='0'
        cy='0'
        gradientTransform='translate(145.163) rotate(56.6947) scale(889.83 1178.15)'
        gradientUnits='userSpaceOnUse'
        id='paint14_radial_1008_23'
        r='1'
      >
        <stop stopColor='#FFAF27' />
        <stop offset='0.564104' stopColor='#DE00B2' />
        <stop offset='1' stopColor='#2F21FA' />
      </radialGradient>
      <radialGradient
        cx='0'
        cy='0'
        gradientTransform='translate(145.163) rotate(56.6947) scale(889.83 1178.15)'
        gradientUnits='userSpaceOnUse'
        id='paint15_radial_1008_23'
        r='1'
      >
        <stop stopColor='#FFAF27' />
        <stop offset='0.564104' stopColor='#DE00B2' />
        <stop offset='1' stopColor='#2F21FA' />
      </radialGradient>
      <radialGradient
        cx='0'
        cy='0'
        gradientTransform='translate(145.163) rotate(56.6947) scale(889.83 1178.15)'
        gradientUnits='userSpaceOnUse'
        id='paint16_radial_1008_23'
        r='1'
      >
        <stop stopColor='#FFAF27' />
        <stop offset='0.564104' stopColor='#DE00B2' />
        <stop offset='1' stopColor='#2F21FA' />
      </radialGradient>
      <radialGradient
        cx='0'
        cy='0'
        gradientTransform='translate(145.163) rotate(56.6947) scale(889.83 1178.15)'
        gradientUnits='userSpaceOnUse'
        id='paint17_radial_1008_23'
        r='1'
      >
        <stop stopColor='#FFAF27' />
        <stop offset='0.564104' stopColor='#DE00B2' />
        <stop offset='1' stopColor='#2F21FA' />
      </radialGradient>
      <radialGradient
        cx='0'
        cy='0'
        gradientTransform='translate(145.163) rotate(56.6947) scale(889.83 1178.15)'
        gradientUnits='userSpaceOnUse'
        id='paint18_radial_1008_23'
        r='1'
      >
        <stop stopColor='#FFAF27' />
        <stop offset='0.564104' stopColor='#DE00B2' />
        <stop offset='1' stopColor='#2F21FA' />
      </radialGradient>
      <radialGradient
        cx='0'
        cy='0'
        gradientTransform='translate(145.163) rotate(56.6947) scale(889.83 1178.15)'
        gradientUnits='userSpaceOnUse'
        id='paint19_radial_1008_23'
        r='1'
      >
        <stop stopColor='#FFAF27' />
        <stop offset='0.564104' stopColor='#DE00B2' />
        <stop offset='1' stopColor='#2F21FA' />
      </radialGradient>
      <radialGradient
        cx='0'
        cy='0'
        gradientTransform='translate(145.163) rotate(56.6947) scale(889.83 1178.15)'
        gradientUnits='userSpaceOnUse'
        id='paint20_radial_1008_23'
        r='1'
      >
        <stop stopColor='#FFAF27' />
        <stop offset='0.564104' stopColor='#DE00B2' />
        <stop offset='1' stopColor='#2F21FA' />
      </radialGradient>
      <radialGradient
        cx='0'
        cy='0'
        gradientTransform='translate(145.163) rotate(56.6947) scale(889.83 1178.15)'
        gradientUnits='userSpaceOnUse'
        id='paint21_radial_1008_23'
        r='1'
      >
        <stop stopColor='#FFAF27' />
        <stop offset='0.564104' stopColor='#DE00B2' />
        <stop offset='1' stopColor='#2F21FA' />
      </radialGradient>
      <radialGradient
        cx='0'
        cy='0'
        gradientTransform='translate(145.163) rotate(56.6947) scale(889.83 1178.15)'
        gradientUnits='userSpaceOnUse'
        id='paint22_radial_1008_23'
        r='1'
      >
        <stop stopColor='#FFAF27' />
        <stop offset='0.564104' stopColor='#DE00B2' />
        <stop offset='1' stopColor='#2F21FA' />
      </radialGradient>
      <radialGradient
        cx='0'
        cy='0'
        gradientTransform='translate(145.163) rotate(56.6947) scale(889.83 1178.15)'
        gradientUnits='userSpaceOnUse'
        id='paint23_radial_1008_23'
        r='1'
      >
        <stop stopColor='#FFAF27' />
        <stop offset='0.564104' stopColor='#DE00B2' />
        <stop offset='1' stopColor='#2F21FA' />
      </radialGradient>
      <radialGradient
        cx='0'
        cy='0'
        gradientTransform='translate(145.163) rotate(56.6947) scale(889.83 1178.15)'
        gradientUnits='userSpaceOnUse'
        id='paint24_radial_1008_23'
        r='1'
      >
        <stop stopColor='#FFAF27' />
        <stop offset='0.564104' stopColor='#DE00B2' />
        <stop offset='1' stopColor='#2F21FA' />
      </radialGradient>
      <radialGradient
        cx='0'
        cy='0'
        gradientTransform='translate(145.163) rotate(56.6947) scale(889.83 1178.15)'
        gradientUnits='userSpaceOnUse'
        id='paint25_radial_1008_23'
        r='1'
      >
        <stop stopColor='#FFAF27' />
        <stop offset='0.564104' stopColor='#DE00B2' />
        <stop offset='1' stopColor='#2F21FA' />
      </radialGradient>
      <radialGradient
        cx='0'
        cy='0'
        gradientTransform='translate(145.163) rotate(56.6947) scale(889.83 1178.15)'
        gradientUnits='userSpaceOnUse'
        id='paint26_radial_1008_23'
        r='1'
      >
        <stop stopColor='#FFAF27' />
        <stop offset='0.564104' stopColor='#DE00B2' />
        <stop offset='1' stopColor='#2F21FA' />
      </radialGradient>
      <radialGradient
        cx='0'
        cy='0'
        gradientTransform='translate(145.163) rotate(56.6947) scale(889.83 1178.15)'
        gradientUnits='userSpaceOnUse'
        id='paint27_radial_1008_23'
        r='1'
      >
        <stop stopColor='#FFAF27' />
        <stop offset='0.564104' stopColor='#DE00B2' />
        <stop offset='1' stopColor='#2F21FA' />
      </radialGradient>
      <radialGradient
        cx='0'
        cy='0'
        gradientTransform='translate(145.163) rotate(56.6947) scale(889.83 1178.15)'
        gradientUnits='userSpaceOnUse'
        id='paint28_radial_1008_23'
        r='1'
      >
        <stop stopColor='#FFAF27' />
        <stop offset='0.564104' stopColor='#DE00B2' />
        <stop offset='1' stopColor='#2F21FA' />
      </radialGradient>
      <radialGradient
        cx='0'
        cy='0'
        gradientTransform='translate(145.163) rotate(56.6947) scale(889.83 1178.15)'
        gradientUnits='userSpaceOnUse'
        id='paint29_radial_1008_23'
        r='1'
      >
        <stop stopColor='#FFAF27' />
        <stop offset='0.564104' stopColor='#DE00B2' />
        <stop offset='1' stopColor='#2F21FA' />
      </radialGradient>
    </defs>
  </svg>
);
